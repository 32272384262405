//COLORS
$blue: #3f4eae;
$border-color: #dfe3e8;
$color-grey-dark: #c4cdd5;
$cadetblue: #5f9ea0;
$primary: #212b36;
$placeholder-color: #212b3696;
$input-color: #212b36;
$lightslategray: #84919c;
$snow: #f9f9f9;
$link-color: #1890ff;
$green: #008000c7;
$helpText: #6d7175;
$red-color: #df3719;
