@import "./variables";

// html {
//   scroll-behavior: smooth;
// }
img {
  max-width: 100%;
}
.beamer_defaultBeamerSelector {
  display: none !important;
}
//Table
table {
  width: 100%;
  border-spacing: 0;

  thead > tr > th {
    font-weight: 600;
    color: #212b36;
    border-bottom: 0.1rem solid #c4cdd5;
    border-top: 0;
    transition: background-color 0.2s ease-in-out;
    vertical-align: middle;
    padding: 1.6rem;
    white-space: nowrap;
    text-align: left;
  }

  tbody > tr > td {
    border-bottom: 0.1rem solid #f4f6f8;
    padding: 1.6rem;
    white-space: nowrap;
    text-align: left;
    transition: background-color 0.2s ease-in-out;
    vertical-align: top;
    white-space: normal;
  }

  .td__overflow {
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

//input field color
input,
textarea {
  color: $input-color;
}

//Placeholder color
input ::placeholder,
textarea ::placeholder {
  color: currentColor !important;
  opacity: 1 !important; /* Firefox */
  font-weight: 400 !important;
  color: $placeholder-color;
}

input :-ms-input-placeholder,
textarea :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: currentColor !important;
  opacity: 1 !important; /* Firefox */
  font-weight: 400 !important;
  color: $placeholder-color;
}

input ::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: currentColor !important;
  opacity: 1 !important; /* Firefox */
  font-weight: 400 !important;
  color: $placeholder-color;
}

p,
h1,
h2,
h3,
h4 {
  word-break: break-word;
}

.tooltip__content {
  a {
    display: inline-block;
    cursor: pointer;
  }
}

/******************** Polaris Css override *******************/
.Polaris-Banner--statusWarning {
  border-radius: var(--p-border-radius-wide, 0 0 3px 3px) !important;
  box-shadow: var(
    --p-banner-border,
    inset 0 3px 0 0 var(--p-override-none, #eec200),
    inset 0 0 0 0 transparent,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  ) !important;
}
.Polaris-Banner--statusCritical {
  border-radius: var(--p-border-radius-wide, 0 0 3px 3px) !important;
  box-shadow: var(
    --p-banner-border,
    inset 0 3px 0 0 var(--p-override-none, #e05d58),
    inset 0 0 0 0 transparent,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  ) !important;
}
.Polaris-FooterHelp__Content {
  padding: 1rem 2rem 1rem 1.6rem !important;
}

.Polaris-Icon--colorWarning svg {
  //fill: #c05717 !important;
  fill: #ffc453 !important;
}

.Polaris-Backdrop {
  background-color: var(--p-backdrop, rgba(33, 43, 54, 0.6)) !important;
}

.Polaris-Frame-Toast__CloseButton {
  .Polaris-Icon__Svg {
    path {
      fill: #fff;
    }
  }
}

[data-lock-scrolling] {
  overflow-y: hidden !important;
  //overflow: scroll !important;
}
.Polaris-TextField__Spinner {
  display: none !important;
}
.Polaris-TextField__Prefix {
  align-self: stretch;
  background: #8080802b;
  margin: 0;
  padding: 0 0.8rem 0 1.2rem;
  vertical-align: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #c4cdd5;
  margin-right: 0.8rem !important;
  margin-left: 0 !important;
}
.Polaris-TextField__Suffix {
  align-self: stretch;
  background: #8080802b;
  margin: 0;
  padding: 0 0.8rem 0 1.2rem;
  vertical-align: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #c4cdd5;
  margin-right: 0 !important;
  margin-left: 0.8rem !important;
}
.Polaris-Select__Input {
  padding: 0.5rem 1.2rem;
}
.Polaris-Tabs__Tab:focus {
  box-shadow: none !important;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo {
  box-shadow: var(
    --p-banner-border,
    inset 0 3px 0 0 var(--p-override-none, #47c1bf),
    inset 0 0 0 0 transparent,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  ) !important;
}

.Polaris-Modal-Dialog__Container {
  background: RGB(23 28 32 / 51%);
}

.Polaris-Popover__PopoverOverlay {
  z-index: 9999 !important;
}
.Polaris-Popover__Content {
  max-height: 40rem !important;
}
.Polaris-Popover--fullWidth {
  min-height: 5rem !important;
}
.Polaris-Popover--fullWidth .Polaris-Popover__Content {
  height: auto !important;
  min-height: 5rem !important;
}
/******************** Antd Css override *******************/
.ant-tooltip-inner {
  box-shadow: var(
    --p-popover-shadow,
    0 0 0 1px rgba(6, 44, 82, 0.1),
    0 2px 16px rgba(33, 43, 54, 0.08)
  );
  border-radius: var(--p-border-radius-base, 3px);
  background-color: var(--p-surface, #212b36);
}
.ant-pagination-item-active {
  border-color: #6371c7 !important;
  a {
    background-color: #6371c7;
    color: #fff !important;
  }
}
.ant-pagination-item:hover {
  border-color: #6371c7;
  a {
    background-color: #6371c7;
    color: #fff !important;
  }
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #6371c7;
  background-color: #6371c7;
  svg > path {
    fill: #fff;
  }
}
.ant-pagination-disabled:hover .ant-pagination-item-link {
  border-color: #d9d9d9 !important;
  background-color: #fff !important;
  svg > path {
    fill: currentColor;
  }
}
.ant-pagination-jump-next-custom-icon,
.ant-pagination-jump-prev-custom-icon {
  .ant-pagination-item-link-icon {
    svg > path {
      fill: #6371c7;
    }
  }
}
.ant-pagination-disabled {
  display: none;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active {
  background-color: #3f4eae26 !important;
}

.intercom-borderless-frame {
  box-shadow: inset RGB(0 0 0 / 16%) 0px 5px 40px;
  border-radius: 8px;
  background: #fff;
}

.rp_popover {
  padding-top: 70px;
  .ant-popover-arrow {
    top: 27%;
  }
}
.tooltip__block {
  display: inherit;
}
.sidebar__nav-item .tooltip__block {
  // display: block !important;
  // width: 100% !important;
}
.settings_rp_tooltip.ant-popover-placement-rightTop {
  top: 847px !important;
  .ant-popover-arrow {
    top: 21px;
  }
}

.additional_amount-tooltip {
  padding-top: 7px;
  z-index: 2;
  .ant-popover-title {
    padding: 8px 30px;
  }
  .ant-popover-inner-content {
    padding: 12px 30px;
  }
  .tooltip__title {
    font-weight: 600;
    font-size: 15px;
  }
  .close__tooltip {
    position: absolute;
    right: 4px;
    top: 11px;
    width: 18px;
    cursor: pointer;
    &:hover {
      background: #e9e9ea;
      border-radius: 6px;
    }
  }
  .tooltip__desc {
    margin-bottom: 12px;
  }
  .upgrade__stats {
    display: flex;
    align-items: center;
    margin-top: 12px;
    img {
      width: 20px;
      margin-right: 8px;
    }
    span {
      font-size: 12px;
      line-height: 1.4;
    }
  }
}
/******************** Toggle BUttons with checkbox*******************/
.toggle__button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  //margin: -20px auto 0 auto;
  overflow: hidden;
  border-radius: 100px;
  .toggle__checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  .toggle__knobs {
    z-index: 2;
    transition: 0.3s ease all;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &::before {
      transition: 0.3s ease all;
      content: "OFF";
      position: absolute;
      top: 3px;
      left: 4px;
      width: 28px;
      height: 28px;
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 9px 4px;
      background-color: #f44336;
      border-radius: 50%;
      transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
    }
  }
  .toggle__layer {
    transition: 0.3s ease all;
    width: 100%;
    //background-color: #ebf7fc;
    background-color: #fcebeb;
    transition: 0.3s ease all;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 100px;
  }
}
.toggle__checked {
  .toggle__knobs::before {
    content: "ON" !important;
    left: 42px !important;
    background-color: $green !important;
    //background-color: #f44336 !important;
  }
  .toggle__layer {
    background-color: #00800026 !important;
    //background-color: #ebf7fc !important;
  }
}
