.spinner {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.51);
  z-index: 21;
  //z-index: 2147483647;
}
.spinner__fixed {
  position: fixed;
}
.spinner__block {
  position: relative;
  min-height: 10rem;
  background: none;
}
.spinner__infinite {
  left: unset;
  top: unset;
  right: unset;
  bottom: 150px;
}
.Polaris-Spinner {
  color: transparent;
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
}
.linkicon__svg {
  height: 2rem;
  width: 2rem;
  path {
    fill: currentColor;
  }
}

// .spinner {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 90vh;
//   z-index: 9999999;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

//Not found
.not__found {
  &-wrapper {
    max-width: 140rem;
    margin-right: auto;
    margin-left: auto;
    background: white;
  }
  .background__Image {
    background-size: 60%;
    background-position: right 10.4rem top 5.6rem;
    overflow: hidden;
    border-radius: 0.6rem;
    box-shadow: 0 1.2rem 3.6rem rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
  }
  .wrapper__div {
    // padding-right: 12.8rem;
    // padding-left: 12.8rem;
    margin-top: 4.8rem;
    //margin-bottom: 12.8rem;
  }
  .text__wrapper {
    min-height: 62rem;
    padding-top: 4rem;
    padding-left: 4rem;
    max-width: 40%;

    h1 {
      max-width: 60rem;
      margin-bottom: 0.3em;
      font-size: 4.8rem;
      font-weight: 700;
      letter-spacing: -1px;
      line-height: 1.3;
      margin-bottom: 0.5em;
      font-size: 4.8rem;
      color: #161d25;
    }
  }
  .info__div {
    visibility: visible;
    font-size: 1.6rem;
    p {
      max-width: 60rem;
      margin-bottom: 1.4em;
      font-size: 1.6rem;
      line-height: 1.55;
      color: #454f5b;
      margin-bottom: 1.2em;
      font-size: 2.2rem;
      font-size: 2.4rem;
    }
  }
}

//Select dropdown
.select {
  select {
    line-height: 2.4rem;
    text-transform: initial;
    letter-spacing: initial;
    position: relative;
    z-index: 20;
    display: block;
    flex: 1 1;
    width: 100%;
    min-width: 0;
    min-height: 3.6rem;
    margin: 0;
    padding: 0.5rem 1.2rem;
    background: none;
    border: 0.1rem solid transparent;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    font-weight: inherit;
    //  -webkit-appearance: none;
    //border: 0.1rem solid var(--p-border, #c4cdd5);
    border: 0.1rem solid #c4cdd569;
    border-radius: var(--p-border-radius-base, 3px);
    box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
    color: #212b36cf;
    -webkit-appearance: menulist;
  }

  .select__error {
    background-color: #fbeae5;
    border-color: #bf0711;
  }
}

//Text Field
.input_field {
  .Polaris-TextField {
    width: 100%;
  }
  .Polaris-InlineError {
    .Polaris-Icon {
      .Polaris-Icon__Svg {
        fill: var(--p-text-critical, #bf0711) !important;
      }
    }
  }
}

/************** Mobile Restrict UI **************/
.mobile__restrict {
  background-color: #1e3d82;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  &-wrapper {
    padding: 2rem;
    img {
      max-width: 25rem;
    }
    h2 {
      color: #fff;
      font-size: 2.7rem;
      margin-top: 6rem;
      margin-bottom: 2rem;
      font-weight: 600;
    }
    p {
      line-height: 1.6;
      font-size: 1.6rem;
      color: #ffffffb3;
    }
  }
}

/************** Tip component  **************/
.tip__wrapper {
  display: flex;
  background-color: #e8f5fa;
  border: 1px solid #1d9bd1;
  border-radius: 6px;
  padding: 12px 18px 12px 18px;
  p {
    line-height: 2rem;
    font-size: 15px;
    margin-left: 2rem;
  }
  img {
    align-self: flex-start;
  }
}

/************** Banner error component  **************/

.banner__error {
  .Polaris-InlineError {
    background: #fff4f4;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #ff000052;
  }
  .Polaris-Icon {
    height: 2.5rem;
    width: 2.5rem;
    svg > path {
      fill: #d72c0d;
    }
  }
  .Polaris-Heading {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

/************** Live icon circle   **************/
.live__icon-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 13px;
  height: 13px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 250%;
    height: 250%;
    box-sizing: border-box;
    margin-left: -75%;
    margin-top: -75%;
    border-radius: 45px;
    background-color: #ff3e43;
    animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ff3e43;
    border-radius: 50px;
    animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

//Embed video
.video__responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video__responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

//Custom Thumbnail

.custom_thumbnail {
  position: relative;
  display: block;
  overflow: hidden;
  min-width: 4rem;
  max-width: 100%;
  width: 6.2rem;
  border-radius: 3px;
  background: #fff;
  border: 0.1rem solid rgba(225, 227, 229, 1);

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
    //box-shadow: inset 0 0 0 1px RGB(33 43 54 / 10%);
    border-radius: 3px;
    z-index: 20;
  }
}

.custom_thumbnail > * {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

//password field
.password__field {
  display: block;
  position: relative;
  .Polaris-TextField__Input {
    font-size: 1.4rem;
    // padding-right: 4rem;
  }
  .Polaris-TextField__Suffix {
    background: none;
    outline: none;
    border: none;
    padding: 0;
  }
  .password__icon {
    // position: absolute;
    // right: 0;
    // top: 41%;
    // z-index: 99;
    // background: no-repeat;
    // outline: none;
    // border: none;
    // cursor: pointer;
    // padding: 0 8px;
    // min-height: 36px;
    // max-width: 36px;
    // transition: all 0s;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 0 8px;
    &:hover {
      background: #80808014 !important;
    }
    .Polaris-Icon svg {
      fill: #8c9195;
    }
  }
}

//info banner
.info__banner {
  margin: 1rem 2.4rem 0 2.4rem;
}

//unauthorize__section
.unauthorize__section {
  margin: 10px auto;
}

//search__ui
.search__ui {
  display: flex;
  .search__field {
    .text__field {
      min-width: 30rem;
      position: relative;
      border-radius: 3px;
      display: flex;
      align-items: center;
      overflow: hidden;
      background: #fff;
      border: 0.1rem solid #c4cdd5;
      //border-right: 0;
      box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
      font-weight: 400;
      line-height: 2.4rem;
      text-transform: initial;
      letter-spacing: initial;
      padding: 5px 10px;
      min-height: 4rem;
      &:focus-within {
        border: 2px solid #3f4eae;
      }

      .search__icon {
        padding-right: 6px;
        white-space: nowrap;
        color: #95a7b7;
        .Polaris-Icon {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
      .search {
        border-right: 0;
        padding: 0;
        -webkit-appearance: none;
        height: auto;
        outline: none;
        border: 0;
        width: 100%;
      }
    }
  }
  .filter__reasons {
    .Polaris-Select__Content {
      min-height: 4rem;
    }
    .Polaris-Select__Backdrop {
      border-radius: 0;
    }
  }
}

//checkmark__circle
.checkmark__circle {
  width: 21px;
  height: 21px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  .checkmark__background {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: #2eb150;
    position: absolute;
  }
  .checkmark__draw {
    border-radius: 5px;
    &::after {
      opacity: 1;
      transform-origin: left top;
      border-right: 3px solid white;
      border-top: 3px solid white;
      border-radius: 1.5px !important;
      content: "";
      left: 3px;
      top: 11px;
      position: absolute;
      transform: scaleX(-1) rotate(135deg);
      height: 13px;
      width: 8px;
    }
  }
}

//SVG icons
.discount__code-icon {
  fill: none;
  max-width: 100%;
  max-inline-size: 100%;
  color: #6b6b6b;
  stroke: currentColor;
  path,
  circle {
    vector-effect: non-scaling-stroke;
    stroke-width: 1.4px;
  }
}
