@import "../../Sass/mixins";

@import "../../Sass/variables";
@import "../../Sass/base";

//Login
.main-section {
  background-image: url("../../assets/background-image-login.png");
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  padding: 20px;
  width: 100%;

  .heading {
    font-size: 28px;
    font-weight: 500;
    color: #fff;
  }
  .main-form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1170px;
    //left content
    .main-form-left-content {
      padding-right: 2rem;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      text-align: left;
      font-size: 16px;
      p {
        margin-bottom: 15px;
        margin-top: 15px;
        font-size: 16px;
      }

      .login-list-content {
        line-height: 26px;
        li {
          margin-bottom: 5px;
          list-style: none;
          display: flex;
          align-items: center;
          .tick-img {
            width: 18px;
            margin-right: 15px;
          }
        }
      }
    }
    //right content
    .main-form-right-content {
      width: 100%;
      max-width: 400px;
      .main-form-card {
        background: #fff;
        color: #000;
        padding: 35px;
        .logo-form {
          max-width: 170px;
          margin: auto;
          margin-bottom: 30px;
          display: block;
        }

        .form-group {
          display: block;
          margin: 0;
          margin-bottom: 10px;
          .with-icon {
            position: relative;

            .form-control {
              padding-left: 45px !important;
            }
            .login-form-icon {
              max-height: 17px;
              position: absolute;
              left: 15px;
              top: 0;
              max-width: 18px;
              margin: auto;
              bottom: 0;
              z-index: 99;
            }
          }
          .with-suffix {
            position: relative;
            .form-control {
              padding-right: 119px !important;
            }
            .input__suffix {
              border-left: 1px solid #c4cdd5ab;
              color: #000;
              position: absolute;
              right: 10px;
              position: absolute;
              top: 0;
              margin: 0;
              padding: 8px;
              background: hsla(0, 0%, 50.2%, 0.17);
              align-self: stretch;
              bottom: 0;
              z-index: 99;
              top: 0;
              bottom: 0;
              right: 0;
              vertical-align: inherit;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .form-input-box {
            margin-top: 12px;
            .form-control {
              outline: none;
              box-shadow: none;
              background: #fff;
              min-height: 49px;
              margin: 0;
              border: 1px solid #e1e1e1;
              font-size: 14px;
              width: 100%;
              border-radius: 3px;
              padding: 0 13px;
            }
          }
        }
      }
      .login-btn,
      .signup-btn {
        background: #1e3d82;
        box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.56);
        width: 100%;
        padding: 10px 15px;
        color: #fff;
        font-weight: 600;
        outline: none;
        border: none;
        cursor: pointer;
        border-radius: 3px;
        min-height: 4.6rem;
        text-transform: uppercase;
      }

      .forgot-password {
        text-align: right;
        margin-bottom: 15px;
      }

      .forgot-password a,
      .account a {
        color: #1e3d82;
        font-weight: 600;
        margin-top: 5px;
        display: inline-block;
      }
      .account {
        text-align: center;
        p {
          color: #7f8c99;
          line-height: 1;
          margin-bottom: 8px;
        }
      }
    }
  }

  .error__field {
    border-color: red !important;
    background: #ff000005 !important;
  }
  .field__disabled {
    background: #919eab26 !important;
    color: #212b369e;
  }
}

.charge__approval {
  margin: 0 auto;
  max-width: 75%;
  padding-top: 70px;
  .welcome__msg {
    margin-bottom: 2.4rem;
    .tab__header {
      @include flexbox();
      @include align-items(center);
      img {
        max-width: 9rem;
      }
      p {
        padding: 2rem;
        color: $primary;
        font-weight: 600;
        font-size: 2rem;
      }
    }
  }

  .info__msg {
    margin-bottom: 2.4rem;
  }

  .plan__btn {
  }
}

.plan__info {
  .plan__heading {
    padding: 2.4rem 4rem;
    .flex__div {
      @include flexbox();
      @include align-items(center);
      .flex__one {
        min-width: 10rem;
        margin-right: 4rem;
        .plan__info-title {
          font-size: 2.5rem;
          font-weight: 600;
          color: $lightslategray;
          margin-bottom: 0.5rem;
          line-height: 1;
        }
        .plan__info-price {
          font-size: 4.8rem;
          font-weight: 600;
          color: $primary;
          line-height: 1;
        }
      }
      .flex__two {
        ul {
          li {
            margin: 0.5rem 0;
            padding: 0 0 0 2rem;
            margin: 7.4px 0;
            position: relative;
            &:before {
              content: "✓";
              color: green;
              font-weight: 800;
              position: absolute;
              left: 0;
              top: 0;
              line-height: 1.2;
            }
          }
        }
      }
    }
  }
  .plan__details {
    padding: 2.4rem 4rem;
    background-color: $snow;
    &-heading {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      color: $primary;
      margin-bottom: 2rem;
      line-height: 1;
    }
    table {
      thead {
        background: linear-gradient(to bottom, #6371c7, #5563c1);
        color: #fff;
        border-radius: 3px;
        border: 1px solid $blue;
        tr > th {
          color: #fff;
          border: none;
        }
      }
      tbody {
        border: 1px solid #ddd;
        tr:nth-child(odd) {
          background-color: #fff;
        }
        tr {
          td {
            border: none;
            padding: 1rem 1.6rem;
          }
        }
      }
    }
  }
}
