/*  **************  FLexbox Mixins ************* */

//Flexbox
// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Inline flexbox display
@mixin inline-flexbox() {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

//Box Sizig
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

/*  **************  Media Query Mixins ************* */

/* Portrait phones and smaller */
$screen-xsm-min: 480px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 686px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

//As you can see, in this example I use naming based on Twitter Bootstrap grid.

@mixin media-small {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin media-medium {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin media-medium-max {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin media-small-min {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

//Portrait phones and smaller
@mixin xsm {
  @media (max-width: #{$screen-xsm-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 769px) {
    @content;
  }
}

// Using mixins

.containerrrrrr {
  padding: 0 15px;
  // 576px window width and more
  @include sm {
    padding: 0 20px;
  }
  // 992px window width and more
  @include lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
  }
  // 1400px window width and more
  @include rwd(1400) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
